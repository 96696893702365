import { Logo } from "@components/logo/logo";
import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    Grid,
    Center,
    rem,
    Stack,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useRouter } from "next/router";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Link from "next/link";
const useStyles = createStyles((theme) => ({
    inner: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
    },

    content: {
        maxWidth: "50%",
        // marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan("md")]: {
            maxWidth: "100%",
            marginRight: 0,
        },
    },

    title: {
        textTransform: "uppercase",
        color: "#8F9BB3",
        fontFamily: `Open Sans, ${theme.fontFamily}`,
        fontSize: "42px",
        lineHeight: "50px",
        fontWeight: 700,
        [theme.fn.smallerThan("md")]: {
            fontSize: rem(28),
        },
    },

    control: {
        [theme.fn.smallerThan("xs")]: {
            flex: 1,
        },
        backgroundColor: "#3366FF",
    },

    image1: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        flex: 1,
        [theme.fn.smallerThan("lg")]: {
            display: "none",
        },
        alignSelf: "center",
    },

    highlight: {
        textTransform: "uppercase",
        color: "#8F9BB3",
        fontFamily: `Open Sans, ${theme.fontFamily}`,
    },
    text: {
        color: "#8F9BB3",
    },
    adminButton: {
        cursor: "pointer",
        position: "absolute",
        [theme.fn.largerThan("md")]: {
            bottom: "20px",
            right: "20px",
        },
        [theme.fn.smallerThan("md")]: {
            top: "10px",
            right: "10px",
        },
    },
}));

const HeroBullets = () => {
    const { classes } = useStyles();
    const router = useRouter();
    // const fpPromise = FingerprintJS.load();

    // (async () => {
    //     // Get the visitor identifier when you need it.
    //     const fp = await fpPromise;
    //     const result = await fp.get();
    // })();
    return (
        <Center
            w={"70%"}
            style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
            }}
            mx={"auto"}
            my={"auto"}
        >
            <div className={classes.inner}>
                <Stack className={classes.content} spacing={"lg"}>
                    <Grid mt={5} gutter={25}>
                        <Grid.Col span={4}>
                            <Center style={{ height: "100%" }}>
                                <Image
                                    src={
                                        "/images/makeme_better-logo-removebg.png"
                                    }
                                    width={100}
                                    height={100}
                                    fit="fill"
                                    className={classes.image1}
                                />
                            </Center>
                        </Grid.Col>
                        <Grid.Col span={8}>
                            <Title className={classes.title}>
                                Lets get <br />
                                <span className={classes.highlight}>
                                    you healthy!
                                </span>
                            </Title>
                        </Grid.Col>
                    </Grid>
                    <Text color="dimmed" mt="md">
                        Our advanced medical AI system utilizes a comprehensive
                        list of symptoms to accurately diagnose conditions and
                        provide tailored treatment recommendations, ensuring
                        personalized care for each patient.
                        <br />
                        <br />
                        Disclaimer: This is a self-diagnostic tool. It is not a
                        certified medical practioner.
                    </Text>

                    <List
                        mt={30}
                        spacing="sm"
                        size="sm"
                        styles={{
                            // @ts-ignore
                            item: classes.text,
                        }}
                        icon={
                            <ThemeIcon size={20} radius="xl" color="#3366FF">
                                <IconCheck size={rem(12)} stroke={1.5} />
                            </ThemeIcon>
                        }
                    >
                        <List.Item>
                            <b>Step 1:</b> Provide information of your Gender
                            and Age etc.
                        </List.Item>
                        <List.Item>
                            <b>Step 2:</b> Fill in the symptoms that you are
                            experiencing in detail to have the best outcome of
                            info
                        </List.Item>
                        <List.Item>
                            <b>Step 3:</b> Read the Diagnosis and follow the
                            Treatment or export a pdf to give to your doctor and
                            confirm the Diagnosis
                        </List.Item>
                    </List>

                    <Group mt={30}>
                        <Button
                            size="md"
                            className={classes.control}
                            onClick={() => router.push("/Auth")}
                        >
                            Lets get started
                        </Button>
                    </Group>
                </Stack>
                <Stack w={"40%"} pl={10}>
                    <Image
                        src={"/images/hero.svg"}
                        width={"518px"}
                        height={"518px"}
                        fit="fill"
                        className={classes.image}
                    />
                </Stack>
            </div>
            <Link
                href={"/Dashboard"}
                style={{ position: "absolute", bottom: "20px", right: "20px" }}
            >
                <Text
                    className={classes.adminButton}
                    size={"sm"}
                    color="#3366FF"
                >
                    Manage
                </Text>
            </Link>
        </Center>
    );
};

export default HeroBullets;
